<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="10" md="8" lg="6">
        <v-card v-if="user.email">
          <v-card-title class="text-h5">Account beherens</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="user.displayName"
              label="Naam"
              required
            ></v-text-field>
            <v-text-field
              v-model="user.email"
              label="Email"
              required
            ></v-text-field>
            <v-text-field
              v-model="password"
              label="Wachtwoord"
              required
            ></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-btn block color="primary" @click="updateProfile"
              >Gegevens bijwerken</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiAccount from "../api/apiAccount";

export default {
  data() {
    return {
      user: {
        email: "",
        displayName: "",
      },
      password: "",
    };
  },
  mounted() {
    this.fetchProfile();
  },
  methods: {
    async fetchProfile() {
      try {
        const response = await apiAccount.getAccount();
        this.user = response;
      } catch (error) {
        console.error("Fout bij ophalen van gebruikers:", error);
      }
    },
    async updateProfile() {
      try {
        const data = {
          email: this.user.email,
          displayName: this.user.displayName,
          password: this.password,
        };
        await apiAccount.updateAccount(data);
        this.$swal.fire("Gelukt!", "Je gegevens zijn bijgewerkt!", "success");
        await this.fetchProfile();
      } catch (error) {
        console.error("Fout bij het bijwerken van het profiel:", error);
        this.$swal.fire(
          "Niet gelukt!",
          "Je gegevens zijn niet bijgewerkt!",
          "error"
        );
      }
    },
    cancel() {
      this.fetchProfile(); // Herlaad oorspronkelijke data
    },
  },
};
</script>
