import { API } from "./api.js";
export default {
  async updateAccount(data) {
    try {
      const response = await API.put("/api/rest/v2/account", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getAccount() {
    const response = await API.get("/api/rest/v2/account");
    return response.data;
  },
};
