import axios from "axios";
const API = axios.create({
  baseURL: window.location.hostname.includes("integratiepartners")
    ? "https://server.postzegels.com"
    : "https://server.postzegels.com",
  timeout: 30000,
});

// import axios from "axios";
// const API = axios.create({
//   baseURL: window.location.hostname.includes("integratiepartners")
//     ? "http://localhost:3000"
//     : "http://localhost:3000",
//   timeout: 30000,
// });

API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const errorStatus = error.response.status;
      if (errorStatus == 401) {
        localStorage.jwtToken = "";
        window.location = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export { API };
