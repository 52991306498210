<template>
  <Account />
</template>

<script>
import Account from "@/components/account/Account";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Account,
  },
};
</script>
