import { API } from "./api.js";

export default {
  async totalNotPaid() {
    try {
      const response = await API.get("/api/rest/v2/invoice/notpaid");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async totalPaid() {
    try {
      const response = await API.get("/api/rest/v2/invoice/totalpaid");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async verrekend() {
    try {
      const response = await API.get("/api/rest/v2/invoice/verrekend");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async totalOrders() {
    try {
      const response = await API.get("/api/rest/v2/invoice/orders/total");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async totalSend() {
    try {
      const response = await API.get("/api/rest/v2/invoice/orders/totalsend");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async totatNotSend() {
    try {
      const response = await API.get(
        "/api/rest/v2/invoice/orders/totalnotsend"
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async totalDayTurnovers() {
    try {
      const response = await API.get(
        "/api/rest/v2/invoice/total/day/turnovers"
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async totalWeekTurnovers() {
    try {
      const response = await API.get(
        "/api/rest/v2/invoice/total/week/turnovers"
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getMonthTurnOvers() {
    try {
      const response = await API.get(
        "/api/rest/v2/invoice/total/month/turnovers"
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getTotalKavels() {
    try {
      const response = await API.get("/api/rest/v2/invoice/total/kavels");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getTotalKavelsVerkocht() {
    try {
      const response = await API.get("/api/rest/v2/invoice/total/sold/kavels");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getTotalNotKavelsVerkocht() {
    try {
      const response = await API.get(
        "/api/rest/v2/invoice/total/notsold/kavels"
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async reportWebshopOrders(data) {
    try {
      const response = await API.post("/api/rest/v2/invoice/bookkeeping", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async ruudReporting(data) {
    try {
      const response = await API.post("/api/rest/v2/ruud/report", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async StandaardruudReporting(data) {
    try {
      const response = await API.post("/api/rest/v2/ruud/report/mail/sendnow", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async downLoadDayTurnover(data) {
    try {
      const response = await API.post(
        "/api/rest/v2/invoice/total/day/turnovers/download",
        data
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async traderReportCost() {
    try {
      const response = await API.post(
        "/api/rest/v2/invoice/total/trader/costreport"
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async traderTableReportCost() {
    try {
      const response = await API.post(
        "/api/rest/v2/invoice/total/trader/costtable"
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getKavelEbayRapport() {
    try {
      const response = await API.get(
        "/api/rest/v2/invoice/ebay/standardreport"
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
